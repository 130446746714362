import restaurant from "./images/Mi-Piace.jpg";
import img_card from "./images/noi1.png";
import imgheaderrestaurant from "./images/headerrestaurant.jpg";
import imgheader from "./images/header.webp";
import img2 from "./images/img4.jpg";
import imgheadermiini from "./images/header.jpg";
import imgheadermiinimb from "./images/header.jpg";

{/*andreeasolcoci@gmail.com*/}

const data = {
    introData: [{
        title: "Invitatii Online",
        mire: "Dorin",
        mireasa: "Andreea",
        data: "30 Iulie 2022",
        data_confirmare: "20 iulie 2022",
        savedata: "~ Salvează Data ~",
        imgdesktop: imgheader,
        imgmobil: imgheader,
        email: "andreeasolcoci@gmail.com", 
       tel: "+44 7926 196131",
       phone: "tel:+447926196131",
       viber: "viber://chat?number=%2B447926196131",
       whatsapp: "https://wa.me/+447926196131",
       messenger: "https://www.messenger.com/t/andreeasolcoci",
    }],
    cardData: [
        {
            id: 1,
            img: restaurant,
            title: "Locația Evenimentului",
            name: "Mi Piace",
            data: "30 iulie 2022, sâmbătă, ora 17:00",
            adress: "str. Trandafirilor 4, or. Chișinău, Moldova",
            harta: "https://g.page/mipiacemd?share",
            iframe: "https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d7586.035684980878!2d28.856119846516744!3d46.99939845651019!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x677e28bd7061dda2!2sMiPiace!5e0!3m2!1sro!2s!4v1654165571448!5m2!1sro!2s" 
        }

    ],
    blogDataNoi: [
        {
            id: 1,
            img: img_card,
            title1: "Ne căsătorim!",
            title2: "VĂ INVITĂM LA NUNTA NOASTRĂ!",
            message: "În ziua în care ne vom uni destinele, vrem să fim înconjurați de cei mai apropiați și dragi oameni nouă. În acest context avem plăcerea de a vă invita să ne fiți martori și să împărtășiți bucuria celui mai semnificativ eveniment din viața noastră.",
        }

    ],
    blogDataNasii: [
        {
            id: 2,
            img: img2,
            title1: "Invitație",
            title2: "Nunta noastră!",
            message: "In ziua in care ne vom uni destinele, vrem să fim înconjurați de cei mai apropiați și dragi oameni nouă. În acest context avem plăcerea de a va invita să ne fiți martori și să împărtășiți bucuria celui mai semnificativ eveniment din viața noastră.",
        }
    ],
    blogDataOmSuflet: [
        {

            img: imgheaderrestaurant,
            title: "Oameni de suflet!",
            message: "Alături de care vom scrie povestea noastră.",
            nasii: "",
            nasii_nume: "",
        }
    ],
    blogDataDrum: [
        {
            imgdesktop: imgheadermiini,
            imgmobil: imgheadermiinimb,
            title: "De aici începe totul.",
            message: "\"Nu știu unde duce drumul meu, dar merg mai bine când te țin de mână.\" – Alfred de Musset",
        }
    ],

}

export default data;